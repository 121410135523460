<template>
  <v-container class="order-page">
    <div class="d-flex justify-space-between align-center rem-md-24 rem-6 primary--text mb-mb-14">
      <img class="can-click" src="@/assets/img/icon-arrow-left.png" alt="arrow-left" @click="$router.go(-1)">
      {{ $t('orderHistory') }}
      <div></div>
    </div>

    <section class="order-list">
      <div v-for="(item, i) in list" :key="i" class="pt-12 can-click" @click="$router.push({name: 'Order-Id', params: {lang: $store.state.locale, id: item.order_id}})">
        <div class="d-flex justify-space-between rem-md-14 rem-6">
          {{ timestampToTime(item.order_time * 1000) }}
          <redeemBtn
            class="order-btn"
            :btnText="$store.state.nowWidth >= 960 ? '查看訂單' : '剩餘數量'"
            rounded
          ></redeemBtn>
        </div>

        <div class="d-flex justify-space-between rem-md-22 rem-8 mb-10">
          <span>{{ item.product_name }}</span>
          <span class="d-flex"><span class="d-none d-md-block">剩餘</span> {{ item.amount }} 支</span>
        </div>
        
        <hr class="line" v-if="i !== list.length - 1" />
      </div>
    </section>
  </v-container>
</template>

<script>
import redeemBtn from '@/components/redeemBtn'
import base from '@/mixin/base'
export default {
  name: "Order",
  mixins: [base],
  data() {
    return {
      list: [],
    }
  },
  components: {
    redeemBtn,
  },
  async mounted() {
    try {
      let result = await this.$store.dispatch('getRedeemOrder')
      if (result.status === 200){
        this.list = result.data || []
      }
    }catch (error){
      console.log('error', error)
    }
  }
}
</script>

<style lang="scss">
.order-page {
  .order-list {
    max-width: 630px;
    width: 100%;
    margin: 0 auto 100px;
  }

  .order-btn {
    width: 133px;
    height: 39px;
    font-size: 26px;
    @include dai_vuetify_md {
      width: 82px;
      height: 24px;
      font-size: 16px;
    }
  }
}
</style>